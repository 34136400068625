<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h6>
                            <a href="javascript:void(0)" class="text-info" @click="back()">
                                <i class="fas fa-arrow-left"></i>
                            </a> 
                            Category Sorting
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <draggable 
                                        v-model="dataList" 
                                        :move="checkMove" 
                                        @end="onEndDrag"
                                    >
                                        <transition-group name="fade">
                                            <div v-for="element in dataList" :key="element.id" class="sort-ele">
                                                <i class="fas fa-sort"></i> {{element.en_name}}
                                            </div>
                                        </transition-group>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import { mapActions } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import draggable from 'vuedraggable'

    export default {
        name: 'categoryList',
        metaInfo: {
            title: "Category Sort",
            titleTemplate: "%s ← BKK Dashboard",
        },
        components: {
            Loading,
            draggable
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                dataList: [],
                ids: [],
                filter: {
                    page: 1,
                    orderBy: "asc",
                    sortKey: "created_at",
                    limit: 10,
                    name: "",
                },
            }
        },
        methods: {
            ...mapActions({
                categorySortListAction: 'categorySortListAction',
                categorySortStoreAction: 'categorySortStoreAction',

            }),
            checkMove(e) {
                // Optional logic to allow/prevent move; you can return true or false.
                return true;
            },
            async onEndDrag() {
                // Update ids array when the dragging is done
                this.ids = this.dataList.map(item => item.id);
    
                await this.categorySortStoreAction({'ids': this.ids})
                    .then(res => {
            
                        this.isLoading = false;
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.error(err);
                    });
                console.log('Updated IDs order:', this.ids);
            },
            async fetchData() {
                this.isLoading = true;
                let option = this.filter;
                await this.categorySortListAction({ ...option })
                    .then(res => {
                        if (res.status == "success") {
                            this.dataList = res.data.data;
                            // Initialize ids based on fetched data
                            this.ids = this.dataList.map(item => item.id);
                        }
                        this.isLoading = false;
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.error(err);
                    });
            },
            back() {
                this.$router.replace({ 
                    path: 'category-list', 
                    query: { page: 1, orderBy: 'desc', sortKey: 'created_at', name: '' } 
                }).catch(() => {});
            }
        },
        mounted() {
            this.fetchData();
        }
    }
</script>

<style scoped lang="scss">
    .sort-ele {
        width: 200px;
        padding: 5px;
        border: 1px solid #dddddd;
        margin-bottom: 6px;
        background-color: #f8f9fa;
    }
</style>
